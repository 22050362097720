<template>
  <div>
    <div class="columns is-gapless">
      <div class="column">
        <div class="padding-x-4 padding-x-5-tablet">
          <div class="section-title">
            Office Info
          </div>
          <b-field label="Office Name">
            <b-input v-model="office.name" />
          </b-field>
          <b-field label="Report To (for invoice)">
            <b-input v-model="office.address" type="textarea" />
          </b-field>
          <div class="columns is-mobile">
            <div class="column">
              <b-field>
                <p class="control">
                  <span class="button is-static">Lat</span>
                </p>
                <b-input v-model="office.latitude" expanded />
              </b-field>
            </div>
            <div class="column">
              <b-field>
                <p class="control">
                  <span class="button is-static">Lon</span>
                </p>
                <b-input v-model="office.longitude" expanded />
              </b-field>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="padding-xy-4 padding-y-0-tablet">
          <div class="card has-shadow-30 padding-xy-4">
            <div class="section-title">
              Licensees
            </div>
            <div v-if="licensees.length" class="is-size-6">
              <div v-for="licenseeOffice in orderBy(licensees, 'name')" :key="licenseeOffice.id" class="columns is-mobile is-vcentered hover_show is-marginless fullwidth">
                <div>
                  {{ licenseeOffice.name }}
                </div>
              </div>
            </div>
            <p v-else>
              No licensees are associated with this office.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="padding-y-6" />
    <manage-buttons @cancel="cancel" @save="save" />
  </div>
</template>

<script>

import Vue2Filters from 'vue2-filters'
import AdminApi from '@/apis/AdminApi'
import ManageButtons from '@/components/navigation/ManageButtons'

export default {
  components: {
    ManageButtons
  },
  mixins: [Vue2Filters.mixin],
  data () {
    return {
      office: {
        name: null,
        address: null,
        latitude: null,
        longitude: null
      },
      licensee: ''
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },
    allLicensees () {
      return this.$store.state.licensees
    },
    licensees () {
      return this.$store.state.licensees.filter(licensee => licensee.officeId === Number(this.id))
    }
  },
  async mounted () {
    await this.$store.dispatch('refreshLicensees')

    if (this.id === 'new') {
      await this.$store.commit('setTitle', 'New Office')
    } else {
      this.office = await AdminApi.getOfficeById(this.id)

      await this.$store.commit('setTitle', 'Office: ' + this.office.name)
    }
  },
  methods: {
    async save () {
      if (this.id === 'new') {
        await AdminApi.createOffice(this.office)
      } else {
        await AdminApi.updateOffice(this.id, this.office)
      }

      this.$router.push({ name: 'manage_offices' })
    },
    async cancel () {
      this.$router.push({ name: 'manage_offices' })
    }
  }
}

</script>
